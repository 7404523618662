"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _PauseRound = _interopRequireDefault(require("@rsuite/icon-font/lib/status/PauseRound"));

// Generated by script, don't edit it please.
var PauseRound = (0, _createSvgIcon["default"])({
  as: _PauseRound["default"],
  ariaLabel: 'pause round',
  category: 'status',
  displayName: 'PauseRound'
});
var _default = PauseRound;
exports["default"] = _default;
module.exports = exports.default;