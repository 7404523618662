"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _PeopleSpeaker = _interopRequireDefault(require("@rsuite/icon-font/lib/people/PeopleSpeaker"));

// Generated by script, don't edit it please.
var PeopleSpeaker = (0, _createSvgIcon["default"])({
  as: _PeopleSpeaker["default"],
  ariaLabel: 'people speaker',
  category: 'people',
  displayName: 'PeopleSpeaker'
});
var _default = PeopleSpeaker;
exports["default"] = _default;
module.exports = exports.default;