"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _MiniProgram = _interopRequireDefault(require("@rsuite/icon-font/lib/platform/MiniProgram"));

// Generated by script, don't edit it please.
var MiniProgram = (0, _createSvgIcon["default"])({
  as: _MiniProgram["default"],
  ariaLabel: 'mini program',
  category: 'platform',
  displayName: 'MiniProgram'
});
var _default = MiniProgram;
exports["default"] = _default;
module.exports = exports.default;