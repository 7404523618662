"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _PlayOutline = _interopRequireDefault(require("@rsuite/icon-font/lib/av/PlayOutline"));

// Generated by script, don't edit it please.
var PlayOutline = (0, _createSvgIcon["default"])({
  as: _PlayOutline["default"],
  ariaLabel: 'play outline',
  category: 'av',
  displayName: 'PlayOutline'
});
var _default = PlayOutline;
exports["default"] = _default;
module.exports = exports.default;