"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Split = _interopRequireDefault(require("@rsuite/icon-font/lib/flow/Split"));

// Generated by script, don't edit it please.
var Split = (0, _createSvgIcon["default"])({
  as: _Split["default"],
  ariaLabel: 'split',
  category: 'flow',
  displayName: 'Split'
});
var _default = Split;
exports["default"] = _default;
module.exports = exports.default;