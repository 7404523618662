"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _WechatTemplate = _interopRequireDefault(require("@rsuite/icon-font/lib/platform/WechatTemplate"));

// Generated by script, don't edit it please.
var WechatTemplate = (0, _createSvgIcon["default"])({
  as: _WechatTemplate["default"],
  ariaLabel: 'wechat template',
  category: 'platform',
  displayName: 'WechatTemplate'
});
var _default = WechatTemplate;
exports["default"] = _default;
module.exports = exports.default;