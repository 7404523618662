"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Send = _interopRequireDefault(require("@rsuite/icon-font/lib/action/Send"));

// Generated by script, don't edit it please.
var Send = (0, _createSvgIcon["default"])({
  as: _Send["default"],
  ariaLabel: 'send',
  category: 'action',
  displayName: 'Send'
});
var _default = Send;
exports["default"] = _default;
module.exports = exports.default;