"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Numbers = _interopRequireDefault(require("@rsuite/icon-font/lib/charts/Numbers"));

// Generated by script, don't edit it please.
var Numbers = (0, _createSvgIcon["default"])({
  as: _Numbers["default"],
  ariaLabel: 'numbers',
  category: 'charts',
  displayName: 'Numbers'
});
var _default = Numbers;
exports["default"] = _default;
module.exports = exports.default;