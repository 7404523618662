"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Storage = _interopRequireDefault(require("@rsuite/icon-font/lib/device/Storage"));

// Generated by script, don't edit it please.
var Storage = (0, _createSvgIcon["default"])({
  as: _Storage["default"],
  ariaLabel: 'storage',
  category: 'device',
  displayName: 'Storage'
});
var _default = Storage;
exports["default"] = _default;
module.exports = exports.default;