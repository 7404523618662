"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Peoples = _interopRequireDefault(require("@rsuite/icon-font/lib/people/Peoples"));

// Generated by script, don't edit it please.
var Peoples = (0, _createSvgIcon["default"])({
  as: _Peoples["default"],
  ariaLabel: 'peoples',
  category: 'people',
  displayName: 'Peoples'
});
var _default = Peoples;
exports["default"] = _default;
module.exports = exports.default;