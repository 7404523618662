"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Creative = _interopRequireDefault(require("@rsuite/icon-font/lib/application/Creative"));

// Generated by script, don't edit it please.
var Creative = (0, _createSvgIcon["default"])({
  as: _Creative["default"],
  ariaLabel: 'creative',
  category: 'application',
  displayName: 'Creative'
});
var _default = Creative;
exports["default"] = _default;
module.exports = exports.default;