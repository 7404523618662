"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Shield = _interopRequireDefault(require("@rsuite/icon-font/lib/application/Shield"));

// Generated by script, don't edit it please.
var Shield = (0, _createSvgIcon["default"])({
  as: _Shield["default"],
  ariaLabel: 'shield',
  category: 'application',
  displayName: 'Shield'
});
var _default = Shield;
exports["default"] = _default;
module.exports = exports.default;