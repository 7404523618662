"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Attachment = _interopRequireDefault(require("@rsuite/icon-font/lib/file/Attachment"));

// Generated by script, don't edit it please.
var Attachment = (0, _createSvgIcon["default"])({
  as: _Attachment["default"],
  ariaLabel: 'attachment',
  category: 'file',
  displayName: 'Attachment'
});
var _default = Attachment;
exports["default"] = _default;
module.exports = exports.default;