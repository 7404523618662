"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _FileUpload = _interopRequireDefault(require("@rsuite/icon-font/lib/action/FileUpload"));

// Generated by script, don't edit it please.
var FileUpload = (0, _createSvgIcon["default"])({
  as: _FileUpload["default"],
  ariaLabel: 'file upload',
  category: 'action',
  displayName: 'FileUpload'
});
var _default = FileUpload;
exports["default"] = _default;
module.exports = exports.default;