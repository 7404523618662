"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _CollaspedFill = _interopRequireDefault(require("@rsuite/icon-font/lib/action/CollaspedFill"));

// Generated by script, don't edit it please.
var CollaspedFill = (0, _createSvgIcon["default"])({
  as: _CollaspedFill["default"],
  ariaLabel: 'collasped fill',
  category: 'action',
  displayName: 'CollaspedFill'
});
var _default = CollaspedFill;
exports["default"] = _default;
module.exports = exports.default;