"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
var _exportNames = {
  Icon: true,
  createIconFont: true,
  createSvgIcon: true
};
exports.createSvgIcon = exports.createIconFont = exports.Icon = void 0;

var _Icon = _interopRequireDefault(require("./Icon"));

exports.Icon = _Icon["default"];

var _createIconFont = _interopRequireDefault(require("./createIconFont"));

exports.createIconFont = _createIconFont["default"];

var _createSvgIcon = _interopRequireDefault(require("./createSvgIcon"));

exports.createSvgIcon = _createSvgIcon["default"];

var _icons = require("./icons");

Object.keys(_icons).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _icons[key]) return;
  exports[key] = _icons[key];
});