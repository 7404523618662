"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _History = _interopRequireDefault(require("@rsuite/icon-font/lib/time/History"));

// Generated by script, don't edit it please.
var History = (0, _createSvgIcon["default"])({
  as: _History["default"],
  ariaLabel: 'history',
  category: 'time',
  displayName: 'History'
});
var _default = History;
exports["default"] = _default;
module.exports = exports.default;