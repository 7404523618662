"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Setting = _interopRequireDefault(require("@rsuite/icon-font/lib/action/Setting"));

// Generated by script, don't edit it please.
var Setting = (0, _createSvgIcon["default"])({
  as: _Setting["default"],
  ariaLabel: 'setting',
  category: 'action',
  displayName: 'Setting'
});
var _default = Setting;
exports["default"] = _default;
module.exports = exports.default;