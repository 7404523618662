"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _WechatOutline = _interopRequireDefault(require("@rsuite/icon-font/lib/platform/WechatOutline"));

// Generated by script, don't edit it please.
var WechatOutline = (0, _createSvgIcon["default"])({
  as: _WechatOutline["default"],
  ariaLabel: 'wechat outline',
  category: 'platform',
  displayName: 'WechatOutline'
});
var _default = WechatOutline;
exports["default"] = _default;
module.exports = exports.default;