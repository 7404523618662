"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Pined = _interopRequireDefault(require("@rsuite/icon-font/lib/status/Pined"));

// Generated by script, don't edit it please.
var Pined = (0, _createSvgIcon["default"])({
  as: _Pined["default"],
  ariaLabel: 'pined',
  category: 'status',
  displayName: 'Pined'
});
var _default = Pined;
exports["default"] = _default;
module.exports = exports.default;