"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Location = _interopRequireDefault(require("@rsuite/icon-font/lib/people/Location"));

// Generated by script, don't edit it please.
var Location = (0, _createSvgIcon["default"])({
  as: _Location["default"],
  ariaLabel: 'location',
  category: 'people',
  displayName: 'Location'
});
var _default = Location;
exports["default"] = _default;
module.exports = exports.default;