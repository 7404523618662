"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _ChangeList = _interopRequireDefault(require("@rsuite/icon-font/lib/file/ChangeList"));

// Generated by script, don't edit it please.
var ChangeList = (0, _createSvgIcon["default"])({
  as: _ChangeList["default"],
  ariaLabel: 'change list',
  category: 'file',
  displayName: 'ChangeList'
});
var _default = ChangeList;
exports["default"] = _default;
module.exports = exports.default;