"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _DoingRound = _interopRequireDefault(require("@rsuite/icon-font/lib/status/DoingRound"));

// Generated by script, don't edit it please.
var DoingRound = (0, _createSvgIcon["default"])({
  as: _DoingRound["default"],
  ariaLabel: 'doing round',
  category: 'status',
  displayName: 'DoingRound'
});
var _default = DoingRound;
exports["default"] = _default;
module.exports = exports.default;