"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Random = _interopRequireDefault(require("@rsuite/icon-font/lib/flow/Random"));

// Generated by script, don't edit it please.
var Random = (0, _createSvgIcon["default"])({
  as: _Random["default"],
  ariaLabel: 'random',
  category: 'flow',
  displayName: 'Random'
});
var _default = Random;
exports["default"] = _default;
module.exports = exports.default;