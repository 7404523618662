"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _BarLineChart = _interopRequireDefault(require("@rsuite/icon-font/lib/charts/BarLineChart"));

// Generated by script, don't edit it please.
var BarLineChart = (0, _createSvgIcon["default"])({
  as: _BarLineChart["default"],
  ariaLabel: 'bar line chart',
  category: 'charts',
  displayName: 'BarLineChart'
});
var _default = BarLineChart;
exports["default"] = _default;
module.exports = exports.default;