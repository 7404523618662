"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _CharacterAuthorize = _interopRequireDefault(require("@rsuite/icon-font/lib/people/CharacterAuthorize"));

// Generated by script, don't edit it please.
var CharacterAuthorize = (0, _createSvgIcon["default"])({
  as: _CharacterAuthorize["default"],
  ariaLabel: 'character authorize',
  category: 'people',
  displayName: 'CharacterAuthorize'
});
var _default = CharacterAuthorize;
exports["default"] = _default;
module.exports = exports.default;