"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Review = _interopRequireDefault(require("@rsuite/icon-font/lib/flow/Review"));

// Generated by script, don't edit it please.
var Review = (0, _createSvgIcon["default"])({
  as: _Review["default"],
  ariaLabel: 'review',
  category: 'flow',
  displayName: 'Review'
});
var _default = Review;
exports["default"] = _default;
module.exports = exports.default;