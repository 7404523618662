"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _ShareOutline = _interopRequireDefault(require("@rsuite/icon-font/lib/action/ShareOutline"));

// Generated by script, don't edit it please.
var ShareOutline = (0, _createSvgIcon["default"])({
  as: _ShareOutline["default"],
  ariaLabel: 'share outline',
  category: 'action',
  displayName: 'ShareOutline'
});
var _default = ShareOutline;
exports["default"] = _default;
module.exports = exports.default;