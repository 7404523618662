"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Wechat = _interopRequireDefault(require("@rsuite/icon-font/lib/platform/Wechat"));

// Generated by script, don't edit it please.
var Wechat = (0, _createSvgIcon["default"])({
  as: _Wechat["default"],
  ariaLabel: 'wechat',
  category: 'platform',
  displayName: 'Wechat'
});
var _default = Wechat;
exports["default"] = _default;
module.exports = exports.default;