"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _PageTop = _interopRequireDefault(require("@rsuite/icon-font/lib/direction/PageTop"));

// Generated by script, don't edit it please.
var PageTop = (0, _createSvgIcon["default"])({
  as: _PageTop["default"],
  ariaLabel: 'page top',
  category: 'direction',
  displayName: 'PageTop'
});
var _default = PageTop;
exports["default"] = _default;
module.exports = exports.default;