"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Scatter = _interopRequireDefault(require("@rsuite/icon-font/lib/charts/Scatter"));

// Generated by script, don't edit it please.
var Scatter = (0, _createSvgIcon["default"])({
  as: _Scatter["default"],
  ariaLabel: 'scatter',
  category: 'charts',
  displayName: 'Scatter'
});
var _default = Scatter;
exports["default"] = _default;
module.exports = exports.default;