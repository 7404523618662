"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _TimeRound = _interopRequireDefault(require("@rsuite/icon-font/lib/status/TimeRound"));

// Generated by script, don't edit it please.
var TimeRound = (0, _createSvgIcon["default"])({
  as: _TimeRound["default"],
  ariaLabel: 'time round',
  category: 'status',
  displayName: 'TimeRound'
});
var _default = TimeRound;
exports["default"] = _default;
module.exports = exports.default;