"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _PageNext = _interopRequireDefault(require("@rsuite/icon-font/lib/direction/PageNext"));

// Generated by script, don't edit it please.
var PageNext = (0, _createSvgIcon["default"])({
  as: _PageNext["default"],
  ariaLabel: 'page next',
  category: 'direction',
  displayName: 'PageNext'
});
var _default = PageNext;
exports["default"] = _default;
module.exports = exports.default;