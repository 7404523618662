"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Media = _interopRequireDefault(require("@rsuite/icon-font/lib/av/Media"));

// Generated by script, don't edit it please.
var Media = (0, _createSvgIcon["default"])({
  as: _Media["default"],
  ariaLabel: 'media',
  category: 'av',
  displayName: 'Media'
});
var _default = Media;
exports["default"] = _default;
module.exports = exports.default;