"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Code = _interopRequireDefault(require("@rsuite/icon-font/lib/file/Code"));

// Generated by script, don't edit it please.
var Code = (0, _createSvgIcon["default"])({
  as: _Code["default"],
  ariaLabel: 'code',
  category: 'file',
  displayName: 'Code'
});
var _default = Code;
exports["default"] = _default;
module.exports = exports.default;