"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Plus = _interopRequireDefault(require("@rsuite/icon-font/lib/action/Plus"));

// Generated by script, don't edit it please.
var Plus = (0, _createSvgIcon["default"])({
  as: _Plus["default"],
  ariaLabel: 'plus',
  category: 'action',
  displayName: 'Plus'
});
var _default = Plus;
exports["default"] = _default;
module.exports = exports.default;