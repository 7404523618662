"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _RemindOutline = _interopRequireDefault(require("@rsuite/icon-font/lib/status/RemindOutline"));

// Generated by script, don't edit it please.
var RemindOutline = (0, _createSvgIcon["default"])({
  as: _RemindOutline["default"],
  ariaLabel: 'remind outline',
  category: 'status',
  displayName: 'RemindOutline'
});
var _default = RemindOutline;
exports["default"] = _default;
module.exports = exports.default;