"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Coupon = _interopRequireDefault(require("@rsuite/icon-font/lib/application/Coupon"));

// Generated by script, don't edit it please.
var Coupon = (0, _createSvgIcon["default"])({
  as: _Coupon["default"],
  ariaLabel: 'coupon',
  category: 'application',
  displayName: 'Coupon'
});
var _default = Coupon;
exports["default"] = _default;
module.exports = exports.default;