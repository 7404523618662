"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _EyeClose = _interopRequireDefault(require("@rsuite/icon-font/lib/status/EyeClose"));

// Generated by script, don't edit it please.
var EyeClose = (0, _createSvgIcon["default"])({
  as: _EyeClose["default"],
  ariaLabel: 'eye close',
  category: 'status',
  displayName: 'EyeClose'
});
var _default = EyeClose;
exports["default"] = _default;
module.exports = exports.default;