"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _PeopleFliter = _interopRequireDefault(require("@rsuite/icon-font/lib/people/PeopleFliter"));

// Generated by script, don't edit it please.
var PeopleFliter = (0, _createSvgIcon["default"])({
  as: _PeopleFliter["default"],
  ariaLabel: 'people fliter',
  category: 'people',
  displayName: 'PeopleFliter'
});
var _default = PeopleFliter;
exports["default"] = _default;
module.exports = exports.default;