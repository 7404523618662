"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _ViewsAuthorize = _interopRequireDefault(require("@rsuite/icon-font/lib/application/ViewsAuthorize"));

// Generated by script, don't edit it please.
var ViewsAuthorize = (0, _createSvgIcon["default"])({
  as: _ViewsAuthorize["default"],
  ariaLabel: 'views authorize',
  category: 'application',
  displayName: 'ViewsAuthorize'
});
var _default = ViewsAuthorize;
exports["default"] = _default;
module.exports = exports.default;