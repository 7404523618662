"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _FolderFill = _interopRequireDefault(require("@rsuite/icon-font/lib/file/FolderFill"));

// Generated by script, don't edit it please.
var FolderFill = (0, _createSvgIcon["default"])({
  as: _FolderFill["default"],
  ariaLabel: 'folder fill',
  category: 'file',
  displayName: 'FolderFill'
});
var _default = FolderFill;
exports["default"] = _default;
module.exports = exports.default;