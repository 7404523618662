"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _InfoOutline = _interopRequireDefault(require("@rsuite/icon-font/lib/status/InfoOutline"));

// Generated by script, don't edit it please.
var InfoOutline = (0, _createSvgIcon["default"])({
  as: _InfoOutline["default"],
  ariaLabel: 'info outline',
  category: 'status',
  displayName: 'InfoOutline'
});
var _default = InfoOutline;
exports["default"] = _default;
module.exports = exports.default;