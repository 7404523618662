"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _SingleSource = _interopRequireDefault(require("@rsuite/icon-font/lib/file/SingleSource"));

// Generated by script, don't edit it please.
var SingleSource = (0, _createSvgIcon["default"])({
  as: _SingleSource["default"],
  ariaLabel: 'single source',
  category: 'file',
  displayName: 'SingleSource'
});
var _default = SingleSource;
exports["default"] = _default;
module.exports = exports.default;