"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _TableColumn = _interopRequireDefault(require("@rsuite/icon-font/lib/application/TableColumn"));

// Generated by script, don't edit it please.
var TableColumn = (0, _createSvgIcon["default"])({
  as: _TableColumn["default"],
  ariaLabel: 'table column',
  category: 'application',
  displayName: 'TableColumn'
});
var _default = TableColumn;
exports["default"] = _default;
module.exports = exports.default;