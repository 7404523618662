"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _InfoRound = _interopRequireDefault(require("@rsuite/icon-font/lib/status/InfoRound"));

// Generated by script, don't edit it please.
var InfoRound = (0, _createSvgIcon["default"])({
  as: _InfoRound["default"],
  ariaLabel: 'info round',
  category: 'status',
  displayName: 'InfoRound'
});
var _default = InfoRound;
exports["default"] = _default;
module.exports = exports.default;